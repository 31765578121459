// eslint-disable-next-line
import React from 'react';
import ReactDOM from 'react-dom';
import App from './parts/App';
import Firebase, { FirebaseContext } from './services';
import 'tailwindcss/dist/base.min.css';
import './styles.css';

ReactDOM.render(
  <FirebaseContext.Provider value={new Firebase()}>
    <App />
  </FirebaseContext.Provider>,
  document.getElementById('root'),
);
